<template>
  <div>
    <v-container fluid>
      <v-widget title="Pedidos de Clientes">
        <template v-slot:toolbar>
          <v-container fluid>
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-text-field flat single-line hide-details clearable solo v-model="busca" @click:clear="limparBusca" @keyup.enter="filtrar($event)" prepend-inner-icon="search" label="Busca" background-color="grey lighten-3"></v-text-field>
              </v-col>
              <v-col cols="10" sm="4">
                <v-select label="Exibição" flat single-line hide-details solo :items="lista_status" v-model="status" @change="filtrar($event)" background-color="grey lighten-3"></v-select>
              </v-col>
              <v-col cols="2" class="text-right pt-2">
                <v-btn v-if="!isSm" depressed color="primary" @click="novo"> NOVO </v-btn>
                <v-btn v-else depressed fab small color="primary" @click="novo">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:widget-content>
          <v-data-table :headers="headers" :options.sync="options" :server-items-length="total" :items="pedidosclientes" :loading="carregando" mobile-breakpoint="0">
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-center">{{ item.numero }}</td>
                <td class="text-center">{{ item.data | formatarData }}</td>
                <td class="text-center">
                  <v-chip pill outlined
                    ><v-avatar left :color="definirCor(item.status)">{{ definirLetra(item.status) }}</v-avatar
                    >{{ item.status }}</v-chip
                  >
                </td>
                <td>{{ item.participante.razaoSocialNome }}</td>
                <td class="text-center">{{ item.quantidade | formatarMoeda }}</td>
                <td class="text-center">{{ item.valor | formatarMoeda }}</td>
                <td class="text-center">
                  <v-menu top right transition="slide-x-transition" @click.native.stop="">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                    </template>
                    <v-list dense>
                      <v-list-item :key="item._id" @click="abrirAlterarStatus(item)">
                        <v-list-item-icon><v-icon>mdi-list-status</v-icon></v-list-item-icon>
                        <v-list-item-title>Alterar Status</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item :key="item._id + 10" @click="editar(item)" data-cy="editar" v-if="item.status == 'Pendente'">
                        <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item :key="item._id + 15" @click="excluir(item._id)" data-cy="remover">
                        <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                        <v-list-item-title>Excluir</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-widget>
    </v-container>

    <v-dialog v-model="dialog_mudar_status" max-width="400px" persistent>
      <v-card>
        <v-toolbar flat dark dense color="primary">
          <v-toolbar-title>Status do Pedido</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4">
          <v-row dense>
            <v-col cols="12">
              <v-radio-group v-model="status_novo">
                <v-radio label="Pendente" value="Pendente"></v-radio>
                <v-radio label="Finalizado" value="Finalizado"></v-radio>
                <v-radio label="Cancelado" value="Cancelado"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog_mudar_status = false" :disabled="carregando" text>Cancelar</v-btn>
          <v-btn color="primary" :disabled="carregando" :loading="carregando" @click="alterarStatus">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import constantes from '@/util/constantes';

export default {
  name: 'ListaPedidosClientes',
  data() {
    return {
      headers: [
        { text: 'Número', value: 'numero', width: '120px', align: 'center', sortable: false },
        { text: 'Data', value: 'data', width: '10%', align: 'center' },
        { text: 'Status', value: 'status', width: '10%', align: 'center' },
        { text: 'Cliente', value: 'cliente', width: '45%' },
        { text: 'Quantidade', value: 'quantidade', width: '15%', align: 'center' },
        { text: 'Valor', value: 'valor', width: '10%', align: 'center' },
        { text: 'Ações', value: 'acoes', align: 'center', width: '7%', sortable: false },
      ],
      busca: '',
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      contantes: constantes,
      lista_status: [
        {
          text: 'Todos',
          value: '',
        },
        {
          text: 'Pendentes',
          value: 'Pendente',
        },
        {
          text: 'Finalizados',
          value: 'Finalizado',
        },
        {
          text: 'Cancelados',
          value: 'Cancelado',
        },
      ],
      status: '',
      dialog_mudar_status: false,
      status_novo: '',
    };
  },

  watch: {
    options: {
      handler() {
        this.listar();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('pedidosClientes', {
      pedidosclientes: 'itens',
      carregando: 'carregando',
      total: 'total',
      pedido_cliente: 'item',
    }),

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    novo() {
      this.$store.commit('pedidosClientes/ZERAR');
      this.$router.push({ name: 'PedidosClientesNovo' });
    },
    listar() {
      this.$store.dispatch('pedidosClientes/listar', this.options);
    },
    limparBusca() {
      this.listar();
    },
    filtrar(event) {
      let newOptions = {
        ...this.options,
        query: this.status,
      };

      this.$store.dispatch('pedidosClientes/listar', newOptions);
    },
    editar(pedido_selecionado) {
      this.$store.commit('pedidosClientes/SET_ITEM', pedido_selecionado);
      this.$router.push({ name: 'PedidosClientesEditar' });
    },
    excluir(pedidocliente_id) {
      this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir este pedido?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('pedidosClientes/excluir', pedidocliente_id);
        }
      });
    },
    abrirAlterarStatus(pedido_selecionado) {
      this.$store.commit('pedidosClientes/SET_ITEM', pedido_selecionado);
      this.status_novo = pedido_selecionado.status;
      this.dialog_mudar_status = true;
    },
    alterarStatus() {
      this.$store.commit('pedidosClientes/ALTERAR_STATUS', this.status_novo);
      this.$store.dispatch('pedidosClientes/atualizar', this.pedido_cliente);
      this.status_novo = '';
      this.dialog_mudar_status = false;
    },
    definirCor(status) {
      if (status == 'Finalizado') {
        return 'green';
      } else if (status == 'Pendente') {
        return 'white';
      } else if (status == 'Cancelado') {
        return 'red';
      }
    },
    definirLetra(status) {
      if (status) {
        return status.charAt(0);
      } else {
        return '';
      }
    },
  },
};
</script>

<style></style>
